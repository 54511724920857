import React, { useEffect, useState } from "react";
import styles from "./AnswerComponent.module.scss";
import { AnswerDefinition } from "../../@types/form-api";
import { Variants } from "framer-motion";
import runIcon from "../../assets/images/run.svg";
import { HotelBedFillIcon } from "../RemixIcons";
import HistoAnswerComponent from "./type/HistoAnswerComponent";
import LikertAnswerComponent from "./type/LikertAnswerComponent";
import TextAnswerComponent from "./type/TextAnswerComponent";
import SelectAnswerComponent from "./type/SelectAnswerComponent";
import DateAnswerComponent from "./type/DateAnswerComponent";
import {
  evaluateConditions,
  hasDynamicValues,
  replaceDynamicValues,
} from "../../tools/formTools";
import { useTranslation } from "react-i18next";
import { Button, Form } from "react-bootstrap";
import MarkdownComponent from "../Markdown/MarkdownComponent";
import { motion } from "framer-motion";

export const getLegend = (data?: string) => {
  let component: any = data || "";
  if (data === "runIcon") {
    component = <img src={runIcon} alt='icon' style={{ width: "30px" }} />;
  } else if (data === "bedIcon") {
    component = (
      <HotelBedFillIcon style={{ fontSize: "30px", color: "#A7B6D1" }} />
    );
  }
  return component;
};

interface AnswerComponentProps {
  answer: AnswerDefinition;
  showError: boolean;
  printMode?: boolean;
  variants?: Variants;
  values: Record<string, any>;
  onChange?: (value: any) => void;
  disabled?: boolean;
}

const AnswerComponent: React.FC<AnswerComponentProps> = ({
  answer,
  values,
  printMode,
  onChange,
  disabled,
  showError,
}) => {
  const { t } = useTranslation("i18n");

  const [inError, setError] = useState(false);
  const [isVisible, setVisible] = useState(!answer.displayConditions);

  const [title, setTitle] = useState<string>();
  const [description, setDescription] = useState<string>();

  const [isDynamic, setDynamic] = useState(false);

  useEffect(() => {
    setTitle(answer.title);
    setDescription(answer.description);
    setDynamic(
      hasDynamicValues(answer.title) || hasDynamicValues(answer.description)
    );
  }, [answer.title, answer.description]);

  useEffect(() => {
    if (isDynamic) {
      setTitle(replaceDynamicValues(answer.title, values));
      setDescription(replaceDynamicValues(answer.description, values));
    }

    setDynamic(
      hasDynamicValues(answer.title) || hasDynamicValues(answer.description)
    );
  }, [values, isDynamic, answer.title, answer.description]);

  useEffect(() => {
    if (!answer.displayConditions) {
      setVisible(true);
      return;
    }

    let visible;
    try {
      visible = evaluateConditions(answer.displayConditions, values);
    } catch (error) {
      console.log(error);
      visible = false;
    }
    setVisible(visible);
    if (!visible && values[answer.id] !== undefined && onChange) {
      onChange(undefined);
    }
  }, [answer.displayConditions, answer.id, onChange, values]);

  useEffect(() => {
    setError(showError && answer.required && values[answer.id] === undefined);
  }, [showError, values, answer]);

  const handleClearResponse = (event: any) => {
    if (onChange) {
      onChange(undefined);
    }
    event.currentTarget.blur();
  };
  const handleNotApplicableChange = (event: any) => {
    if (onChange) {
      onChange(event.target.checked ? null : undefined);
    }
  };

  const isNotApplicable = values[answer.id] === null;

  return !isVisible ? (
    <></>
  ) : (
    <div
      className={`${styles.answer} page-break ${
        inError ? styles.answerError : ""
      }`}
    >
      <div>
        <h4
          className={`${styles.answerTitle} ${
            isNotApplicable ? styles.notApplicable : ""
          } `}
        >
          <MarkdownComponent
            text={`${title}${
              answer.required ? `` : `$$(${t("common.optional")})$$`
            }`}
          />
        </h4>

        {isNotApplicable && (
          <div className={styles.answerDesc}>
            <Form.Check
              className={styles.checkbox}
              id={`isNotApplicable$-${answer.id}`}
              type='switch'
              label={t(`pages.forms.notApplicable`)}
              checked={isNotApplicable}
              onChange={handleNotApplicableChange}
            />
          </div>
        )}

        {!disabled &&
          !printMode &&
          !answer.required &&
          values[answer.id] !== undefined && (
            <Button
              size='sm'
              className={styles.clearButton}
              onClick={handleClearResponse}
              autoFocus={false}
              variant='secondary'
            >
              x
            </Button>
          )}
      </div>
      <motion.div
        transition={{ duration: 0.25 }}
        initial={{
          // scale: !isNotApplicable ? 0 : 1,
          height: !isNotApplicable ? 0 : "unset",
        }}
        animate={{
          scale: !isNotApplicable ? 1 : 0,
          height: !isNotApplicable ? "unset" : 0,
        }}
        variants={{
          hidden: {
            opacity: 0,
          },
          show: {
            opacity: 1,
          },
        }}
      >
        {description && (
          <div
            className={`${styles.answerDesc} ${styles.hiddenable} ${
              isNotApplicable ? styles.hidden : styles.visible
            }`}
          >
            <MarkdownComponent text={description} />
          </div>
        )}
        {answer.enableNotApplicable && (
          <div className={styles.answerDesc}>
            <Form.Check
              className={styles.checkbox}
              id={`isNotApplicable$-${answer.id}`}
              type='switch'
              label={t(`pages.forms.notApplicable`)}
              checked={isNotApplicable}
              onChange={handleNotApplicableChange}
            />
          </div>
        )}

        <div
          className={`formAnswer ${styles.hiddenable} ${
            isNotApplicable ? styles.hidden : styles.visible
          }`}
        >
          {answer.measureType?.type === "histo" && (
            <HistoAnswerComponent
              value={values[answer.id]}
              id={answer.id}
              measureType={answer.measureType}
              onChange={onChange}
              disabled={disabled}
              printMode={printMode}
            />
          )}
          {answer.measureType?.type === "likert" && (
            <div className={styles.likert}>
              <LikertAnswerComponent
                value={values[answer.id]}
                id={answer.id}
                measureType={answer.measureType}
                onChange={onChange}
                disabled={disabled}
                printMode={printMode}
              />
            </div>
          )}
          {answer.measureType?.type === "text" && (
            <div className={styles.text}>
              <TextAnswerComponent
                required={answer.required}
                value={values[answer.id]}
                id={answer.id}
                measureType={answer.measureType}
                onChange={onChange}
                disabled={disabled}
                printMode={printMode}
              />
            </div>
          )}
          {answer.measureType?.type === "select" && (
            <div className={styles.select}>
              <SelectAnswerComponent
                value={values[answer.id]}
                id={answer.id}
                measureType={answer.measureType}
                onChange={onChange}
                disabled={disabled}
                printMode={printMode}
              />
            </div>
          )}
          {answer.measureType?.type === "date" && (
            <div className={styles.date}>
              <DateAnswerComponent
                value={values[answer.id]}
                id={answer.id}
                measureType={answer.measureType}
                onChange={onChange}
                disabled={disabled}
                printMode={printMode}
              />
            </div>
          )}
          {inError && (
            <div className={styles.error}>
              {t("common.validations.required")}
            </div>
          )}
        </div>
      </motion.div>
    </div>
  );
};

export default AnswerComponent;
